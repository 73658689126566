var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"charecteristc main-block"},[_c('div',{staticClass:"charecteristc__block"},[_c('div',{staticClass:"charecteristc__img-block"},[_c('div',{staticClass:"charecteristc__img-big",style:(`background-image:url(${_vm.image(_vm.allCarsData.body)})`)},[(_vm.allCarsData.navigation && _vm.allCarsData.navigation !== 'null')?_c('div',{staticClass:"car-items top-left"},[_vm._m(0),_c('span',{staticClass:"car-items-text"},[_vm._v(" "+_vm._s(_vm.allCarsData.navigation || '-')+" ")])]):_vm._e(),(_vm.allCarsData.characteristics[0].temp)?_c('div',{staticClass:"car-items top-middle"},[_vm._m(1),_c('span',{staticClass:"car-items-text"},[_vm._v(" "+_vm._s(_vm.allCarsData.tracker?.sensors?.temp ?? '-')+" °С ")])]):_vm._e(),_c('div',{staticClass:"car-items top-right"},[_vm._m(2),_c('span',{staticClass:"car-items-text"},[_vm._v(" "+_vm._s(_vm.allCarsData.tracker?.sensors?.speed ?? '-')+" км/ч ")])]),_c('div',{staticClass:"car-items middle-right"},[_vm._m(3),_c('span',{staticClass:"car-items-text"},[_vm._v(" "+_vm._s(_vm.allCarsData.tracker?.sensors?.oil ?? '-')+" л ")])]),_c('div',{staticClass:"car-items bot-left"},[_vm._m(4),_c('span',{staticClass:"car-items-text"},[_vm._v(" "+_vm._s(_vm.listWheels[_vm.allCarsData.wheel])+" ")])]),_c('div',{staticClass:"car-items bot-right"},[_vm._m(5),_c('span',{staticClass:"car-items-text"},[_vm._v(" "+_vm._s(_vm.thousandsWithSpaces(_vm.allCarsData.tracker?.sensors?.odometr ?? '-' ))+" км ")])])]),_c('div',{staticClass:"charecteristc__img-items flex-only"},_vm._l((_vm.allCarsData.images),function(item,index){return _c('div',{key:index,staticClass:"charecteristc__img-item",class:{
            'charecteristc__img-hide': index >= 5,
            'charecteristc__img-plus': index === 4,
          },on:{"click":function($event){index === 4 ? (_vm.showImage = true) : ''}}},[(index < 5)?_c('div',{staticClass:"charecteristc__img-box"},[_c('img',{staticClass:"charecteristc__img-small",attrs:{"src":_vm.$url + '/' + item.path,"alt":""}})]):_vm._e()])}),0)]),(_vm.showImage)?_c('div',{staticClass:"close-black",on:{"click":function($event){_vm.showImage = false}}}):_vm._e(),(_vm.allCarsData.images && _vm.allCarsData.images.length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImage),expression:"showImage"}],staticClass:"thumb-example"},[_c('swiper',{ref:"swiperTop",staticClass:"swiper gallery-top",attrs:{"options":_vm.swiperOptionTop}},[_vm._l((_vm.allCarsData.images),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide-img",style:(`background-image:url(${_vm.$url + '/' + item.path})`)})}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2),_c('swiper',{ref:"swiperThumbs",staticClass:"swiper gallery-thumbs",attrs:{"options":_vm.swiperOptionThumbs}},_vm._l((_vm.allCarsData.images),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide-img",style:(`background-image:url(${_vm.$url + '/' + item.path})`)})}),1)],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"car-items-title flex"},[_vm._v(" GPS навигатор "),_c('img',{attrs:{"src":require("@/assets/svg/gps.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"car-items-title flex"},[_vm._v(" Рефрижератор "),_c('img',{attrs:{"src":require("@/assets/svg/temperature.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"car-items-title flex"},[_vm._v(" Скорость "),_c('img',{attrs:{"src":require("@/assets/svg/speed.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"car-items-title flex"},[_vm._v(" Топливо "),_c('img',{attrs:{"src":require("@/assets/svg/petrol.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"car-items-title flex"},[_vm._v(" Шины "),_c('img',{attrs:{"src":require("@/assets/svg/wheel.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"car-items-title flex"},[_vm._v(" Пробег "),_c('img',{attrs:{"src":require("@/assets/svg/mileage.svg"),"alt":""}})])
}]

export { render, staticRenderFns }