<template>
  <div class="charecteristc main-block">
    <div class="charecteristc__block  ">
      <div  class="charecteristc__img-block">
        <div
          class="charecteristc__img-big"
          :style="`background-image:url(${image(allCarsData.body)})`"
        >
          <div v-if="allCarsData.navigation && allCarsData.navigation !== 'null'" class="car-items top-left">
            <span class="car-items-title flex">
              GPS навигатор   
              <img src="@/assets/svg/gps.svg" alt="">
            </span>
            <span class="car-items-text">
              {{ allCarsData.navigation || '-' }}
            </span>
          </div>
          <div v-if="allCarsData.characteristics[0].temp" class="car-items top-middle">
            <span class="car-items-title flex">
              Рефрижератор   
              <img src="@/assets/svg/temperature.svg" alt="">
            </span>
            <span class="car-items-text">
              {{ allCarsData.tracker?.sensors?.temp ?? '-' }} °С
            </span>
          </div>
          <div class="car-items top-right">
            <span class="car-items-title flex">
              Скорость   
              <img src="@/assets/svg/speed.svg" alt="">
            </span>
            <span class="car-items-text">
              {{ allCarsData.tracker?.sensors?.speed ?? '-' }} км/ч
            </span>
          </div>
          <div class="car-items middle-right">
            <span class="car-items-title flex">
              Топливо   
              <img src="@/assets/svg/petrol.svg" alt="">
            </span>
            <span class="car-items-text">
              {{ allCarsData.tracker?.sensors?.oil ?? '-' }} л
            </span>
          </div>
          <div class="car-items bot-left">
            <span class="car-items-title flex">
              Шины   
              <img src="@/assets/svg/wheel.svg" alt="">
            </span>
            <span class="car-items-text">
              {{ listWheels[allCarsData.wheel] }}
            </span>
          </div>
          <div class="car-items bot-right">
            <span class="car-items-title flex">
              Пробег   
              <img src="@/assets/svg/mileage.svg" alt="">
            </span>
            <span class="car-items-text">
              {{ thousandsWithSpaces(allCarsData.tracker?.sensors?.odometr ?? '-' ) }} км
            </span>
          </div>
        </div>
        <div class="charecteristc__img-items flex-only">
          <div
            v-for="(item, index) in allCarsData.images"
            :key="index"
            class="charecteristc__img-item"
            :class="{
              'charecteristc__img-hide': index >= 5,
              'charecteristc__img-plus': index === 4,
            }"
            @click="index === 4 ? (showImage = true) : ''"
          >
            <div v-if="index < 5" class="charecteristc__img-box">
              <img class="charecteristc__img-small" :src="$url + '/' + item.path" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="showImage" class="close-black" @click="showImage = false" />
      <div
        v-show="showImage"
        v-if="allCarsData.images && allCarsData.images.length"
        class="thumb-example"
      >
        <swiper ref="swiperTop" class="swiper gallery-top" :options="swiperOptionTop">
          <swiper-slide
            v-for="(item, index) in allCarsData.images"
            :key="index"
            class="slide-img"
            :style="`background-image:url(${$url + '/' + item.path})`"
          />
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
        </swiper>
        <swiper ref="swiperThumbs" class="swiper gallery-thumbs" :options="swiperOptionThumbs">
          <swiper-slide
            v-for="(item, index) in allCarsData.images"
            :key="index"
            class="slide-img"
            :style="`background-image:url(${$url + '/' + item.path})`"
          />
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "infoImageSlider",
  title: "",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    allCarsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showImage: false,
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      listWheels: {
        null: "-",
        'studded': "Шипованные",
        'sticky': "Липучка",
        'summer': "Летние",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop?.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs?.$swiper;
      if (swiperTop) {
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
      }
    });
  },
  methods: {
    thousandsWithSpaces(x) {
      return x.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    image(x) {
      switch (x) {
        case 'fura':
          if(this.allCarsData.trailer_id !== null)
            return require('@/assets/svg/truck_large.svg')
          return require('@/assets/svg/truck_without_trailer.svg')
        case 'hatchback': 
          return require('@/assets/svg/largus.svg')
        case 'sprinter': 
          return require('@/assets/svg/sprinter.svg')
        case 'trailer': 
          return require('@/assets/svg/trailer.svg')
        default:
          return '/'
      }
    }
  },
};
</script>

<style scoped>
.charecteristc__block {
  align-items: flex-start;
}
.charecteristc__img-block {
  /* width: 48%; */
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.charecteristc__img-big {
  height: 300px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-size: contain;
}
.charecteristc__img-items {
  margin: 30px 0 15px 0;
  justify-content: space-between;
}
.charecteristc__img-item {
  position: relative;
}
.charecteristc__img-plus::before {
  content: "5+";
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  color: #fff;
  background-color: #707070;
}
.charecteristc__img-box {
  width: 105px;
  height: 105px;
  cursor: pointer;
}
.charecteristc__img-box:not(:last-child) {
  margin-right: 6px;
}
.charecteristc__img-hide {
  display: none;
}
.charecteristc__img-small {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.thumb-example {
  background-color: #ffffff;
  padding: 5px 10px;
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 90%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.swiper .slide-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.gallery-top {
  height: 80%;
  width: 100%;
  border-radius: 10px;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.8;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
.car-items {
  width: 120px;
  background-color: #F2F2F8;
  position: absolute;
  border-radius: 8px;
  padding: 5px 10px;
  font-family: sans-serif;
}
.car-items-title {
  color: #ACACAC;
  font-size: 10px;
}
.car-items-text {
  color: #000000;
  font-size: 14px;
  background-color: #F2F2F8;
}
.top-left {
  margin: 50px 0 0 70px;
}
.top-middle {
  margin: 40px 0 0 280px;
}
.top-right {
  margin: 50px 0 0 460px;
}
.middle-right {
  margin: 143px 0 0 430px;
}
.bot-left {
  margin: 210px 0 0 120px;
}
.bot-right {
  margin: 223px 0 0 369px;
}
</style>
